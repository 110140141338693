/* eslint-disable no-unused-vars */
export enum FeeType {
  MortgageInspectionFee = 'MortgageInspectionFee',
  ProcessingFee = 'ProcessingFee',
  UnderwritingFee = 'UnderwritingFee',
  AppraisalFee = 'AppraisalFee',
  CreditReportFee = 'CreditReportFee',
  UserDefinedFee816 = 'UserDefinedFee_816', // LendorAttorneyFee
  CommitmentFee = 'UserDefinedFee_820',
  PrepaidInterest = 'PrepaidInterest',
  MortgageBrokerFee = 'MortgageBrokerFee',
  PropertyTaxes = 'PropertyTaxes',
}

export interface EncompassLoanFee {
  feeType: FeeType;
  borPaidAmount?: number;
}
