import { apiAxios } from 'src/util';

export const orderAccountChek = (data) => () => {
  return apiAxios({
    method: 'post',
    url: '/services/accountchek',
    data,
  });
};

export const getAccountChek = async (loanGuid, loId) => {
  const res = await apiAxios.get(`/services/accountchek/${loanGuid}/${loId}`);
  return res.data;
};

export const downloadVOAReportPDF = async (pdfId, orderId, loanGuid, loId) => {
  try {
    const res = await apiAxios({
      method: 'post',
      url: `/services/accountchek/${loanGuid}/${loId}/${pdfId}/${orderId}/pdf`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    link.href = url;
    link.setAttribute('download', 'VOE Summary.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res;
  } catch (err) {
    return err;
  }
};
