export enum ContactType {
  Seller = 'SELLER',
  BuyersAgent = 'BUYERS_AGENT',
  SellersAgent = 'SELLERS_AGENT',
  FloodInsurance = 'FLOOD_INSURANCE',
  TitleCompany = 'TITLE_COMPANY',
  SettlementAgent = 'SETTLEMENT_AGENT',
  Investor = 'INVESTOR',
}

export interface EncompassLoanContact {
  name?: string;
  contactName?: string;
  phone?: string;
  email?: string;
  contactType?: ContactType;
  insuranceDeterminationDate?: Date;
  insuranceFloodZone?: boolean;
}
