import { apiAxios } from 'src/util';

export const createInvestor = async (data) => {
  const res = await apiAxios.post('investors', data);
  return res.data;
};

export const updateInvestor = async (id, data) => {
  const res = await apiAxios.patch(`investors/${id}`, data);
  return res.data;
};

export const deleteInvestor = async (id) => {
  await apiAxios.delete(`investors/${id}`);
  return id;
};

/**
 * @param {{ view?: 'minimal' | 'basic' | 'entity' | 'full' }} params
 */
export const fetchInvestors = async ({ view } = { view: null }) => {
  try {
    const res = await apiAxios.get('investors', {
      params: {
        view,
      },
    });
    return res.data;
  } catch (err) {
    console.warn(err);
  }
};
