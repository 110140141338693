import { apiAxios } from 'src/util';

export * from 'src/components/admin/store/disclosurequeries/actions';
export * from 'src/components/admin/store/loanOfficers/actions';
export * from 'src/components/admin/store/webhooks/actions';
export * from 'src/components/compliance/store/addresses/actions';
export * from 'src/components/compliance/store/loanOfficers/actions';
export * from 'src/components/admin/store/appraisalFees/actions';
export * from 'src/components/admin/store/investors/actions';
export * from 'src/components/admin/store/roles/actions';
export * from 'src/components/admin/store/maventRuleExclusions/actions';
export * from './accounting';
export * from './appraisals';
export * from './appSettings';
export * from './cinchTasks';
export * from './closings';
export * from './disclosures';
export * from './efolder';
export * from './encompassActions';
export * from './encompassSchema';
export * from './flippedProperties';
export * from './flood';
export * from './fixAndFlip';
export * from './google';
export * from './invitations';
export * from './loan';
export * from './loanProgram';
export * from './loanAssociates';
export * from './loanFields';
export * from './loanFolders';
export * from './loanOfficer';
export * from './loanStorage';
export * from './loe';
export * from './locks';
export * from './logicApps';
export * from './mavent';
export * from './messages';
export * from './notifications';
export * from './pipeline';
export * from './pipelineFields';
export * from './preapproval';
export * from 'src/components/loanOfficer/specialreports/store/disclosureReports/actions';
export * from 'src/components/loanOfficer/reporting/store/actions';
export * from './loanAssociates';
export * from './serviceStatus';
export * from './termsheet';
export * from './credit';
export * from './departments';
export * from './loanServices/automatedUnderwritingSystem';
export * from './verificationOfEmployment';
export * from './noi';
export * from './rates';
export * from './loanServices/taxVerification';
export * from './loanServices/AccountChek';
export * from './loanServices/titleInvoice';
export * from './loanServices/fraudguard';
export * from './loanServices/optimalBlue';
export * from './nqmInvestor';
export * from './submitTo';
export * from './loanException';
export * from './multiFamily';
export * from './signNow';
export * from './consent';
export * from './smartsheet';
export * from './aggregateDatesSetup';

export const setApplicationError = (errString, errObj) => ({
  type: 'set_app_error',
  payload: {
    errString,
    errObj,
  },
});

export const fetchPublicApplicationConfig = async () => {
  const res = await apiAxios.get('appconfiguration/public');
  return res.data;
};
