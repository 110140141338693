import axios from 'axios';
import { apiAxios, anyAxios, delay, getFileMimeType } from 'src/util';

export const handleEFolderFileUpload = async (file, uploadUrlData) => {
  const headers = {
    'Content-Type': getFileMimeType(file.name),
  };
  if (uploadUrlData.authorizationHeader) {
    headers.Authorization = uploadUrlData.authorizationHeader;
  }

  if (uploadUrlData.multiChunkRequired) {
    const { chunkList, commitUrl } = uploadUrlData.multiChunk;
    let offset = 0;
    for (const { size, uploadUrl } of chunkList) {
      const chunk = file.slice(offset, size);
      await anyAxios.put(uploadUrl, chunk, {
        headers,
      });
      offset += size;
    }

    await anyAxios.post(commitUrl, null, {
      headers: {
        Authorization: uploadUrlData.authorizationHeader,
      },
    });
  } else {
    await anyAxios.put(uploadUrlData.uploadUrl, file, {
      headers,
    });
  }
};
export const uploadFileToEFolderObsolete =
  ({ uploadTo, title, file, pairIndex, loanGuid }) =>
    async () => {
      const formData = new FormData();
      formData.append('file', file);

      const queryParams =
      '?uploadTo=' +
      uploadTo +
      '&title=' +
      title +
      '&guid=' +
      loanGuid +
      '&pairIndex=' +
      pairIndex;

      try {
        const res = await axios.post('UploadFile' + queryParams, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return res.data;
      } catch (e) {
        return e;
      }
    };

export const getEFolderDocsObsolete = (guid) => () => {
  return axios.post('GetDocs', {
    guid,
  });
};

export const getEFolderAttachmentsObsolete = (guid, entityId) => () => {
  return axios.post('GetAttachment', {
    guid,
    entityId,
  });
};

export const fetchEFolderDocuments = (loanGuid) => async (dispatch) => {
  const res = await apiAxios.get(`loans/${loanGuid}/efolder/documents`);
  dispatch({
    type: 'set_efolder_documents',
    data: {
      loanGuid,
      documents: res.data,
    },
  });
};

export const fetchEFolderAttachments = (loanGuid) => async (dispatch) => {
  const res = await apiAxios.get(`loans/${loanGuid}/efolder/attachments`);
  dispatch({
    type: 'set_efolder_attachments',
    data: {
      loanGuid,
      attachments: res.data,
    },
  });
};

export const fetchEFolderAttachmentUrls =
  (loanGuid, attachmentId) => async (dispatch) => {
    const res = await apiAxios.get(
      `loans/${loanGuid}/efolder/attachments/${attachmentId}/urls`,
    );
    dispatch({
      type: 'set_efolder_attachment_urls',
      data: {
        loanGuid,
        attachmentId,
        urls: res.data,
      },
    });
  };

export const createEFolderDocument =
  (loanGuid, applicationId, title) => async (dispatch) => {
    const res = await apiAxios.post(`loans/${loanGuid}/efolder/documents`, {
      title,
      applicationId,
    });
    dispatch({
      type: 'add_efolder_document',
      data: {
        loanGuid,
        document: res.data,
      },
    });
    return res.data;
  };

export const uploadEFolderDocumentAttachment =
  (loanGuid, documentGuid, file) => async (dispatch) => {
    const uploadUrlRes = await apiAxios.post(
      `loans/${loanGuid}/efolder/documents/${documentGuid}/upload`,
      {
        title: file.name,
        file: {
          size: file.size,
          name: file.name,
          contentType: getFileMimeType(file.name),
        },
      },
    );
    const uploadUrlData = uploadUrlRes.data;
    await handleEFolderFileUpload(file, uploadUrlData);
    let tries = 3;
    while (tries > 0) {
      try {
        const res = await apiAxios.get(
          `loans/${loanGuid}/efolder/attachments/${uploadUrlData.attachmentId}`,
        );
        dispatch({
          type: 'add_efolder_attachment',
          data: {
            loanGuid,
            documentGuid,
            attachment: res.data,
          },
        });
        break;
      } catch (e) {
        console.warn(e);
        tries--;
        await delay(1000);
      }
    }
  };

export const downloadEFolderAttachment = async (
  loanGuid,
  attachmentId,
  attachmentName,
) => {
  const res = await apiAxios.get(
    `loans/${loanGuid}/efolder/attachments/${attachmentId}/download`,
    {
      responseType: 'arraybuffer',
    },
  );
  const blobFile = new Blob([res.data]);
  const objectUrl = URL.createObjectURL(blobFile);
  const a = document.createElement('a');
  a.download = attachmentName;
  a.href = objectUrl;
  a.click();
  a.remove();
  URL.revokeObjectURL(objectUrl);
};

export const assignUnassignedEFolderAttachment =
  (loanGuid, documentGuid, attachmentIds) => async (dispatch, getState) => {
    const attachments = getState().efolder[loanGuid]?.attachments ?? [];
    for (const attachmentId of attachmentIds) {
      await apiAxios.put(
        `loans/${loanGuid}/efolder/documents/${documentGuid}/attachments/${attachmentId}`,
      );
      const match = attachments.find((att) => att.id === attachmentId);
      dispatch({
        type: 'assign_efolder_attachment',
        data: {
          loanGuid,
          documentGuid,
          attachment: match,
        },
      });
    }
  };

export const deleteEFolderAttachment =
  (loanGuid, attachmentId) => async (dispatch) => {
    await apiAxios.delete(
      `loans/${loanGuid}/efolder/attachments/${attachmentId}`,
    );
    dispatch({
      type: 'delete_efolder_attachment',
      data: {
        loanGuid,
        attachmentId,
      },
    });
  };
