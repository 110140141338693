import axios from 'axios';
import { compareAsc, format, parse } from 'date-fns';

const buildFormatStr = (str) => {
  const parts = str.split('/');
  const chars = ['M', 'd', 'y'];
  return [0, 1, 2].map((x) => chars[x].repeat(parts[x].length)).join('/');
};

export const fetchBlockedClosingDates = () => async () => {
  try {
    const res = await axios.get('GetBlockedDates');
    const data = res.data;

    const results = data
      .map((x) => {
        if (x.length === 10) return parse(x, 'MM/dd/yyyy', new Date());
        const formatStr = buildFormatStr(x);
        return parse(x, formatStr, new Date());
      })
      .sort((x, y) => compareAsc(x, y))
      .map((x) => format(x, 'MM/dd/yyyy'));
    return [...new Set(results)];
  } catch (e) {
    return e;
  }
};
