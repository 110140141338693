import {
  GET_CINCH_TASKS,
  SET_CINCH_TASKS,
  UPDATE_CINCH_TASK,
  ADD_CINCH_TASKS,
  SET_CINCH_TASKS_WORKING,
  REMOVE_CINCH_TASK,
} from '../types';
import { apiAxios } from 'src/util';
import { handleEFolderFileUpload } from 'src/actions/efolder';

export const getCinchTasks =
  (loanGuid, applicationGuid = undefined) =>
    async (dispatch) => {
      dispatch({
        type: GET_CINCH_TASKS,
      });
      const res = await apiAxios.get(`loans/${loanGuid}/tasks`, {
        params: {
          applicationGuid,
        },
      });
      if (res.data) {
        dispatch({
          type: SET_CINCH_TASKS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: SET_CINCH_TASKS,
          payload: [],
        });
      }
    };

export const updateCinchTask = (loanGuid, id, data) => async (dispatch) => {
  const res = await apiAxios.patch(`loans/${loanGuid}/tasks/${id}`, data);
  if (res.data && !res.data.attachments?.length) {
    delete res.data.attachments;
  }
  dispatch({
    type: UPDATE_CINCH_TASK,
    payload: res.data,
  });
};

export const createCinchTask = (loanGuid, data) => async (dispatch) => {
  dispatch({
    type: SET_CINCH_TASKS_WORKING,
    payload: true,
  });

  const res = await apiAxios.post(`loans/${loanGuid}/tasks`, data);

  dispatch({
    type: ADD_CINCH_TASKS,
    payload: [res.data],
  });
  return res.data;
};

export const deleteCinchTask = (loanGuid, id) => async (dispatch) => {
  await apiAxios.delete(`loans/${loanGuid}/tasks/${id}`);

  dispatch({
    type: REMOVE_CINCH_TASK,
    payload: id,
  });
};

export const deleteCinchTaskAttachment =
  (loanGuid, taskId, attachmentId) => async (dispatch) => {
    const res = await apiAxios.delete(
      `loans/${loanGuid}/tasks/${taskId}/files/${attachmentId}`,
    );

    dispatch({
      type: UPDATE_CINCH_TASK,
      payload: res.data,
    });
  };

export const uploadCinchTaskAttachment =
  (
    loanGuid,
    taskId,
    file,
    categoryForOther = null,
    applicationGuidForOther = null,
  ) =>
    async (dispatch) => {
      const uploadUrlRes = await apiAxios.post(
      `loans/${loanGuid}/tasks/${taskId}/files`,
      {
        title: file.name,
        size: file.size,
        category: categoryForOther,
        applicationGuid: applicationGuidForOther,
      },
      );
      const uploadUrlData = uploadUrlRes.data;
      await handleEFolderFileUpload(file, uploadUrlData);
      const attachmentId = uploadUrlData.attachmentId;

      const createdTaskId = uploadUrlRes.headers['location'].split('/').pop();
      await apiAxios.post(
      `loans/${loanGuid}/tasks/${createdTaskId}/files/${attachmentId}/complete`,
      );

      const taskRes = await apiAxios.get(
        uploadUrlRes.headers['location'].substring(1),
      );
      if (taskId > 0) {
        dispatch({
          type: UPDATE_CINCH_TASK,
          payload: taskRes.data,
        });
      } else {
        dispatch({
          type: ADD_CINCH_TASKS,
          payload: [taskRes.data],
        });
      }
    };

export const SendDailyTaskEmailOnLoan = (loanGuid, data) => async () => {
  await apiAxios.post(`loans/${loanGuid}/settings`, data);
};

export const getLoanSettings = (loanGuid) => async () => {
  const res = await apiAxios.get(`loans/${loanGuid}/settings`);
  return res.data;
};

export const sendCinchTaskEmail = (loanGuid) => async () => {
  await apiAxios.post(`loans/${loanGuid}/tasks/send-daily-email`);
};
