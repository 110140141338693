import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLoanOfficers } from 'src/actions';
import Loading from 'src/components/baseComponents/Loading';
import { useCurrentLoanOfficer, useLoanOfficers } from 'src/hooks';
import PropTypes from 'prop-types';

const emptyArray = [];

export const LoanOfficerPicker = ({
  onChange,
  includeSelf,
  includeEncompassDuplicates,
  value,
  label = 'Select loan officer',
  autoFocus,
  required,
  helperText,
  error,
  excludeLoanOfficerIds = emptyArray,
  ...props
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loanOfficers = useLoanOfficers();
  const currentLoanOfficer = useCurrentLoanOfficer();
  const [inputValue, setInputValue] = useState('');

  const loanOfficersFiltered = useMemo(() => {
    return loanOfficers
      .filter(
        (x) =>
          (includeSelf || x.id !== currentLoanOfficer.id) &&
          (includeEncompassDuplicates ||
            x.encompassUser !== currentLoanOfficer.encompassUser) &&
          !excludeLoanOfficerIds.includes(x.id),
      )
      .map((x) => ({
        ...x,
        firstLetter: x.name[0]?.toUpperCase(),
      }));
  }, [
    loanOfficers,
    includeSelf,
    currentLoanOfficer.id,
    currentLoanOfficer.encompassUser,
    includeEncompassDuplicates,
    excludeLoanOfficerIds,
  ]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(fetchLoanOfficers({ view: 'basic' }));
      setLoading(false);
    })();
  }, [dispatch]);

  return (
    <div
      className='flex flex-container flex-vertical'
      style={{ width: '100%', marginBottom: 4 }}
    >
      {loading ? (
        <Loading label='Fetching loan officers...' />
      ) : (
        <Autocomplete
          className='flex'
          {...props}
          value={value}
          onChange={(e, value) => onChange(e, value || null)}
          inputValue={inputValue}
          onInputChange={(_, value) => setInputValue(value)}
          getOptionLabel={(opt) => opt.name || ''}
          groupBy={(option) => option.firstLetter}
          options={loanOfficersFiltered}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant='outlined'
              size='small'
              autoFocus={autoFocus}
              required={required}
              helperText={helperText}
              error={error}
            />
          )}
        />
      )}
    </div>
  );
};

LoanOfficerPicker.defaultProps = {
  onChange: () => null,
};

LoanOfficerPicker.propTypes = {
  includeEncompassDuplicates: PropTypes.bool,
  includeSelf: PropTypes.bool,
  excludeLoanOfficerIds: PropTypes.arrayOf(PropTypes.number),
};
