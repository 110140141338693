export * from './customfields/EncompassCustomCommercial';
export * from './customfields/EncompassCustomFieldsMapped';
export * from './customfields/EncompassCustomLLC';
export * from './customfields/EncompassCustomRTL';
export * from './customfields/EncompassCustomNQM';
export * from './EncompassApplication';
export * from './EncompassAUSTrackingLog';
export * from './EncompassBorrower';
export * from './EncompassClosingCost';
export * from './EncompassClosingDocument';
export * from './EncompassCustomField';
export * from './EncompassEmployment';
export * from './EncompassFhaVaLoan';
export * from './EncompassFlood';
export * from './EncompassGfe2010';
export * from './EncompassGfe2010Fee';
export * from './EncompassGfe2010Section';
export * from './EncompassGiftGrant';
export * from './EncompassLoan';
export * from './EncompassLoanAssociate';
export * from './EncompassLoanContact';
export * from './EncompassLoanFee';
export * from './EncompassLoanProductData';
export * from './EncompassMailingAddress';
export * from './EncompassOtherAsset';
export * from './EncompassOtherIncomeSource';
export * from './EncompassOtherLiability';
export * from './EncompassProperty';
export * from './EncompassRateLock';
export * from './EncompassRegulationZ';
export * from './EncompassReoProperty';
export * from './EncompassResidence';
export * from './EncompassTql';
export * from './EncompassUldd';
export * from './EncompassURLAAlternateName';
export * from './EncompassVaLoanData';
export * from './EncompassVod';
export * from './EncompassVodItem';
export * from './EncompassVol';
export * from './EncompassMilestone';

export enum BorrowerType {
  Borrower = 'Borrower',
  CoBorrower = 'CoBorrower',
  Both = 'Both',
}
