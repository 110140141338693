import { apiAxios } from 'src/util';

export const sendSignNowInvites = async (loanGuid, invites) => {
  await apiAxios.post(
    `signnow/${invites.documentId}/send-invites?loanGuid=${loanGuid}`,
    invites,
  );
};

export const resendPendingSignNowInvites = async (signNowDocumentId) => {
  await apiAxios.post(`signnow/${signNowDocumentId}/resend-invites`);
};

export const uploadToSignNowWithActions = async (data) => {
  const res = await apiAxios.post('signnow/upload', data);
  return res.data;
};

export const getSignNowDocuments = async (loanGuid) => {
  const res = await apiAxios.get(`signnow/loans/${loanGuid}`);
  return res.data;
};

export const getSignNowEditLink = async (signNowDocumentId) => {
  const res = await apiAxios.get(`signnow/${signNowDocumentId}/edit`);
  return res.data;
};

export const downloadSignNowDocument = async (documentId, documentName) => {
  const res = await apiAxios({
    method: 'post',
    url: `signnow/${documentId}/download`,
    responseType: 'arraybuffer',
  });
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName); // or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};
